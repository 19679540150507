<template>
  <div class="ny_pad">
    <div class="menu_ico">
      <top_menu></top_menu>
      <!-- ho_menu -->
    </div>
    <!-- menu_ico -->
    <div class="gywm_lay1 content_lay">

      <div class="content_cont" style="    background-color: #fff;
    padding: 10px; font-size: 16px; color: rgb(27, 27, 27); display: inline-block; width: 75%; text-align: left;">
        <router-link :to="{path:'content',query:{id:item.id}}" v-for="(item, index) in data1" :key="index">
          <dl class="sousuo_list">
            <dt>{{item.title}}

              <div style="font-size: 12px;    color: #000;  font-weight: normal;  float: right;">
                {{(item.publishTime||'').slice(0,11).replace(/-/g,'/')}}
              </div>

            </dt>
            <dd>{{item.description}}</dd>
          </dl>
        </router-link>

        <VuePagination
            ref="vuePagination"
            :current-page="pagination.currentPage"
            :pageSize="pagination.pageSize"
            :totals="pagination.totals"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />

      </div>

    </div>

    <!-- content_lay -->
    <fo_oter></fo_oter>
    <!-- footer -->
  </div>
</template>

<script>
import VuePagination from "../components/page.vue";
import top_menu from '../components/menu.vue'
import fo_oter from '../components/footer.vue'
import {GETLISTDATA} from '../apis/api.js'

export default {
  name: 'searchlist',
  components: {
    top_menu,fo_oter,VuePagination
  },
  data() {
    return {
      catid: this.$route.query.catid,
      data1:[],
      pagination: {
        pageSize: 10, // 显示的条数
        totals: 0, // 总数
        currentPage: 1, // 当前第几页
      },
    };
  },
  created() {
    this.showlunbo(1,this.pagination.pageSize);
  },
  methods:{
    showlunbo(cp,ps){
      GETLISTDATA({catid:this.catid,pageNum:cp,pageSize:ps}).then((res) => {
        if(res.code == 200){
          this.data1 = res.rows;
          this.pagination.totals = res.total ;
        }
      });
    },
    // 改变每页的显示数量
    handleSizeChange(val) {
      // console.log('val', val);
      //this.pagination.pageSize = val.pageSize;
      let pageSize = this.pagination.pageSize;
      if(val.pageSize){
        pageSize = val.pageSize;
      }
      this.showlunbo(val.currentPage,pageSize);
    },
    // 翻页
    handleCurrentChange(val) {
      // console.log('val', val);
      //val.totals = this.pagination.totals;
      this.showlunbo(val.currentPage,this.pagination.pageSize);
    },
  }
}
</script>