<template>
<div class="ny_pad">
</div>
</template>

<script>
export default {
  name: 'sy',
  components: {
  },
  data() {
    return {
      syid: this.$route.query
    };
  },
  mounted() {
	  this.go(this.$route.query);
  },
  methods: {
      go(syid) {
        if (syid) {
          for (let k in syid){
            window.location.href = "https://qrzs.huishandairy.com/86.1000.17/" + k;
          }
        }
      }
  }
}
</script>